<template>
  <div class="customerPage">
    <!-- Customer Form -->
    <form @submit.prevent="saveChanges">
      <va-card stripe="secondary">

        <!-- Controls -->
        <div class="row">
          <div class="flex xs6 md10">
            <h1 class="display--1 text--capitalize">{{ mode }} Customer Form</h1>
          </div>
          <div class="flex xs6 md2">
            <va-button
              outline
              medium
              color="info"
              icon="fa fa-arrow-left"
              type="button"
              v-on:click="goBack">
              Back
            </va-button>
          </div>
        </div>

        <!-- Row 1 -->
        <div class="row">
          <!-- Customer Full Name -->
          <div class="flex md7 xs12">
            <va-input
              label="Full Name"
              v-model="customer.fullname"
              placeholder="Type Full Name here">
              <va-icon
                slot="prepend"
                color="success"
                name="fa fa-header"
              />
            </va-input>
          </div>

          <!-- Customer id -->
          <div class="flex md5 xs12">
            <va-input
              label="Customer ID"
              v-model="customer.uid"
              v-bind:disabled="true"
              placeholder="Type Customer ID here"
            >
              <va-icon
                slot="prepend"
                color="gray"
                name="fa fa-id-card"
              />
            </va-input>
          </div>
        </div>

        <!-- Row 2 -->
        <div class="row">
          <!-- Customer Email -->
          <div class="flex md5 xs12">
            <va-input
              label="Email Address"
              v-bind:disabled="true"
              v-model="customer.email"
              placeholder="Type Customer Email here">
              <va-icon
                slot="prepend"
                color="gray"
                name="fa fa-envelope"
              />
            </va-input>
          </div>

          <!-- Customer Phone -->
          <div class="flex md5 xs12">
            <va-input
              label="Phone Number"
              v-model="customer.phone"
              placeholder="Type Customer Phone here"
            >
              <va-icon
                slot="prepend"
                color="success"
                name="fa fa-phone-square"
              />
            </va-input>
          </div>

          <!-- Marketing Allowed -->
          <div class="flex md2 xs12">
            <va-checkbox
              class="flex md4 xs12"
              :label="$t('Marketing')"
              v-model="customer.marketing"
            />
          </div>
        </div>
      </va-card>

      <!-- Address Information -->
      <va-card class="my-3" stripe="info">

        <h1>Address</h1>
        <div class="row mt-2">
          <!-- Address Line 1 -->
          <div class="flex md6 xs12">
            <va-input
              label="Address Line 1"
              v-model="customer.address[0]"
              placeholder="Type Address Line 1 here">
              <va-icon
                slot="prepend"
                color="success"
                name="fa fa-home"
              />
            </va-input>
          </div>

          <!-- Address Line 2 -->
          <div class="flex md6 xs12">
            <va-input
              label="Address Line 2"
              v-model="customer.address[1]"
              placeholder="Type Address Line 2 here"
            >
              <va-icon
                slot="prepend"
                color="success"
                name="fa fa-road"
              />
            </va-input>
          </div>
        </div>

        <div class="row">
          <!-- Address City -->
          <div class="flex md4 xs12">
            <va-input
              label="City / Town"
              v-model="customer.address[2]"
              placeholder="Type City / Town here">
              <va-icon
                slot="prepend"
                color="success"
                name="fa fa-building-o"
              />
            </va-input>
          </div>

          <!-- Address Country -->
          <div class="flex md5 xs12">
            <va-item-section>
              <div class="px-3">
                <va-select
                  label="Country"
                  searchable
                  :options="countries"
                  v-model="customer.address[3]"
                  placeholder="Type Country here"
                />
              </div>
            </va-item-section>
          </div>

          <!-- Address Post Code -->
          <div class="flex md3 xs12">
            <va-input
              label="Post Code"
              v-model="customer.address[4]"
              placeholder="Type Post Code here">
              <va-icon
                slot="prepend"
                color="success"
                name="fa fa-qrcode"
              />
            </va-input>
          </div>
        </div>
      </va-card>

      <va-card class="my-3">
        <va-data-table
          :fields="fields"
          :data="customer.orders"
          :per-page="100"
          hoverable
          no-data-label="Nothing to show right now :("
        >

          <template slot="Total" slot-scope="props">
            <p>€{{ props.rowData.Total }}</p>
          </template>

          <template slot="actions" slot-scope="props">
            <va-popover :message="`${$t('tables.delete')} Order ${props.rowData.OrderID}`" placement="left">
              <va-button flat small type="button" color="danger" icon="fa fa-trash" v-on:click="deleteFromTable(props.rowData, props.rowIndex)"/>
            </va-popover>
          </template>

        </va-data-table>

        <!-- Controls Bottom -->
        <div class="row mt-4">

          <!-- Go Back -->
          <div class="flex xs12 md9">
            <va-button
              outline
              medium
              color="info"
              type="button"
              icon="fa fa-arrow-left"
              v-on:click="goBack">
              Back
            </va-button>
          </div>

          <!-- Save form -->
          <div class="flex xs12 md3">
            <va-button
              medium
              color="info"
              type="submit"
              icon="fa fa-save"
            >
              Save Customer
            </va-button>
          </div>
        </div>
      </va-card>
    </form>
  </div>
</template>

<script>
import { usersCollection } from '@/firebaseConfig.js'
import CountriesList from '@/data/CountriesList'

export default {
  name: 'customerPage',
  props: ['customer', 'mode'],
  data () {
    return {
      countries: CountriesList,
    }
  },
  computed: {
    fields () {
      return [{
        name: 'Date',
        title: this.$t('Order Date'),
        sortField: 'Date',
        width: '25%',
      }, {
        name: 'OrderID',
        title: this.$t('Order ID'),
        sortField: 'OrderID',
        width: '25%',
      }, {
        name: 'PaymentMethod',
        title: this.$t('Payment Method'),
        sortField: 'PaymentMethod',
        width: '25%',
      }, {
        name: '__slot:Total',
        title: this.$t('Order Total'),
        sortField: 'Total',
        width: '25%',
      }, {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
  },
  methods: {
    goBack: function () {
      this.$emit('goBack')
    },

    saveChanges: function () {
      const _this = this
      usersCollection
        .doc(this.customer.uid)
        .set(this.customer)
        .then(() => {
          _this.$emit('saveCustomer')
          _this.goBack()
        })
    },

    deleteFromTable: function (orderData, orderIndex) {
      let realIndex
      const orderIndexRev = (this.customer.orders.length - 1) - orderIndex

      if (orderData.orderID === this.customer.orders[orderIndex].orderID) {
        realIndex = orderIndex
      } else if (orderData.orderID === this.customer.orders[orderIndexRev].orderID) {
        realIndex = orderIndexRev
      }

      this.customer.orders.splice(realIndex, 1)
    },
  },
}
</script>
