<template>
  <div class="customers">

    <!-- Header -->
    <ObjectPageHeader
      v-bind:objectName="objectName"
      v-on:refreshData="refreshData($event)">
    </ObjectPageHeader>

    <!-- Info Tiles -->
    <div class="row">
      <div
        v-for="(info, idx) in infoTiles.slice(0,2)"
        :key="idx"
        class="flex xs12 md4"
      >
        <va-card class="mb-4" :color="info.color">
          <p class="display-2 mb-0" style="color: white;">{{ info.value }}</p>
          <p> {{ info.text }}</p>
        </va-card>
      </div>

      <div class="flex xs12 md4">
        <va-card class="mb-4" :color="infoTiles[2].color">
          <p v-for="(country, index) in infoTiles[2].value"
            :key="index"
            class="display-6 mb-0"
            style="color: white;">
            No {{ index+1 }}: {{ country }}
          </p>
          <p> {{ infoTiles[2].text }}</p>
        </va-card>
      </div>
    </div>

    <!-- Data table header -->
    <va-card v-if="!showCustomer" title="All Customers">
      <div class="row align--center">
        <div class="flex xs12 md6">
          <va-input
            :value="term"
            placeholder="Search by Customer Email or Name or ID"
            @input="search"
            removable
          >
            <va-icon name="fa fa-search" slot="prepend" />
          </va-input>
        </div>

        <div class="flex xs12 md3 offset--md3">
          <va-select
            v-model="perPage"
            :label="$t('tables.perPage')"
            :options="perPageOptions"
            noClear
          />
        </div>
      </div>

      <!-- Data Table Contents -->
      <va-data-table
        :fields="fields"
        :data="filteredData"
        :per-page="parseInt(perPage)"
        hoverable
        no-data-label="Nothing to show right now :("
        :loading="loading"
      >

        <template slot="address" slot-scope="props">
          <p>{{ props.rowData.address.slice(2).join(', ') }}</p>
        </template>

        <template slot="orders" slot-scope="props">
          <p>{{ props.rowData.orders.length }}</p>
        </template>

        <template slot="actions" slot-scope="props">
          <va-popover :message="`View ${props.rowData.fullname}'s information`" placement="left">
            <va-button flat small color="secondary" icon="fa fa-eye" v-on:click="viewCustomer(props.rowData)"/>
          </va-popover>
        </template>

      </va-data-table>
    </va-card>

    <CustomerPage v-else
      v-bind:mode="mode"
      v-bind:customer="selectedCustomer"
      v-on:saveCustomer="saveCustomer()"
      v-on:goBack="goBack()">
    </CustomerPage>
  </div>
</template>

<script>
import { debounce } from 'lodash'
import { usersCollection } from '@/firebaseConfig.js'
import CustomerPage from '@/components_tc/customers/CustomerPage.vue'
import ObjectPageHeader from '@/components_tc/utilities/ObjectPageHeader.vue'
export default {
  name: 'customers',
  components: {
    ObjectPageHeader,
    CustomerPage,
  },
  data () {
    return {
      term: null,
      mode: '',
      perPage: '25',
      customers: [],
      objectName: '',
      loading: false,
      showCustomer: false,
      selectedCustomer: {},
      perPageOptions: ['10', '25', '50', '100'],
      infoTiles: [{
        color: 'secondary',
        value: '0',
        text: 'Registered Customers',
        icon: '',
      }, {
        color: 'info',
        value: '0',
        text: 'Best Customer',
        icon: '',
      }, {
        color: 'warning',
        value: '0',
        text: 'Top Customer Countries',
        icon: '',
      }],
    }
  },
  computed: {
    Customers: function () {
      return this.$store.state.customers
    },
    fields () {
      return [{
        name: 'fullname',
        title: this.$t('Full Name'),
        sortField: 'fullname',
        width: '20%',
      }, {
        name: 'email',
        title: this.$t('Email'),
        sortField: 'email',
        width: '30%',
      }, {
        name: 'phone',
        title: this.$t('Phone Number'),
        sortField: 'phone',
        width: '13%',
      },
      {
        name: '__slot:address',
        title: this.$t('Delivery Address'),
        sortField: 'address',
        width: '27%',
      },
      {
        name: '__slot:orders',
        title: this.$t('Orders'),
        width: '5%',
      },
      {
        name: '__slot:actions',
        dataClass: 'text-right',
      }]
    },
    filteredData () {
      if (!this.term || this.term.length < 1) {
        return this.customers
      }

      return this.customers.filter(item => {
        return item.fullname.toLowerCase().startsWith(this.term.toLowerCase()) || item.email.toLowerCase().startsWith(this.term.toLowerCase()) || item.uid.toLowerCase().startsWith(this.term.toLowerCase())
      })
    },
  },
  methods: {
    search: debounce(function (term) {
      this.term = term
    }, 400),

    refreshData: function (event) {
      this.getCustomerData(event)
    },

    goBack: function () {
      this.showCustomer = false
    },

    viewCustomer: function (customerData) {
      this.mode = 'edit'
      this.selectedCustomer = customerData
      this.showCustomer = true
    },

    saveCustomer () {
      this.showToast(
        'Customer Updated',
        {
          icon: 'fa-check-circle',
          position: 'bottom-right',
          duration: 2500,
          fullWidth: false,
        },
      )
    },

    getCustomerData: function (event) {
      var _this = this
      this.loading = true

      let topCustomer = {}
      let topCustomerCountries = {}

      if (this.Customers.customerData === undefined || this.Customers.customerData.length === 0 || event != null) {
        usersCollection
          .get()
          .then(function (dataSnapshot) {
            const jsonCustomers = []

            dataSnapshot.forEach(function (item) {
              var itemData = item.data()
              jsonCustomers.push(itemData)

              // Get Top Countries
              const country = itemData.address[3]
              topCustomerCountries[country] ? topCustomerCountries[country] += 1 : topCustomerCountries[country] = 1

              // Get Top Customer
              if (Object.keys(topCustomer).length !== 0) {
                topCustomer = (itemData.orders.length > topCustomer.orders.length)
                  ? itemData : topCustomer
              } else {
                topCustomer = itemData
              }
            })

            _this.customers = jsonCustomers.sort((a, b) => (a.fullname > b.fullname) ? 1 : -1)
            topCustomerCountries = Object.keys(topCustomerCountries).sort(function (a, b) { return topCustomerCountries[b] - topCustomerCountries[a] })
            topCustomerCountries = topCustomerCountries.length > 3 ? topCustomerCountries.slice(0, 3) : topCustomerCountries
            const customerStoreCollection = {
              customerData: _this.customers,
              topCustomer: topCustomer,
              topCustomerCountries: topCustomerCountries,
            }

            _this.$store.commit('setCustomers', customerStoreCollection)
            _this.updateInfoTiles(customerStoreCollection)
            _this.loading = false
          })
          .catch(err => {
            alert(err.message)
          })
      } else {
        this.customers = this.Customers.customerData
        this.updateInfoTiles(this.Customers)
        this.loading = false
      }
    },

    updateInfoTiles: function (customers) {
      this.infoTiles[0].value = customers.customerData.length
      this.infoTiles[1].value = customers.topCustomer.fullname
      this.infoTiles[2].value = customers.topCustomerCountries
    },

  },
  mounted () {
    this.objectName = this.$options.name
    this.getCustomerData(null)
  },
}
</script>
